<template>
    <div style="margin-top: 45px;">

        <img v-if="loading" src="/assets/images/loadingimgcar.png" alt="Loading...">

    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: true,
        };
    },
    mounted() {
        this.checkDevice();
    },
    methods: {
        checkDevice() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;

            if (/android/i.test(userAgent)) {
                this.loading = false;
                window.location.href = "https://play.google.com/store/apps/details?id=in.ibidz.customer"
            } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                this.loading = false;
                window.location.href = "https://apps.apple.com/in/app/ibidz/id6470708315"
            }

        }
    }
}
</script>